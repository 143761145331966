
.footer-container {
    width: 100%;
    height: 100%;
    color: rgb(255, 255, 255);
    background-image: url('../../images/mercedes-background.JPG');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 14px -2px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-overlay {
    background-color: rgba(0, 0, 0, 0.685);
    text-align: left;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem 0;
    letter-spacing: 1px;
    line-height: 25px;
    min-height: 40vh;
}

.footer-overlay h3 {
    margin: 0 auto;
    padding-bottom: 2rem;
    word-break: keep-all;
    font-size: 6vh;
    justify-content: space-evenly;
    color: rgb(255, 255, 255);
}

.my-icons {
    margin: 10px 0 0 0;
    width: 4rem;
}

.desktop-footer a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.mobile-footer {
    display: none;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}


.desktop-footer {
    display: flex;
    align-items: left;
    justify-content: space-evenly;
    margin: auto;
    display: flex;
    
    line-height: 28px;
}
.desktop-footer li {
    list-style: none;
    font-size: x-large;
    line-height: 50px;
}



.copyright {
    text-align: center;
    font-weight: 100;
    font-size: xx-small;
    margin: 0px;
    padding-top: 40px;
    padding-bottom: 20px;
    color: #777272;
}

.contact-container {
    display: flex;
    align-items: left;
    justify-content: space-evenly;
    display: flex;
}

.contact-container ul {
    padding: 0;
}

.contact-us {
    margin: 1rem 3rem;
    line-height: 40px;
}


.material-icons {
    color: rgb(182, 182, 182);
    margin: 0;
    padding: 20px auto 50px auto;
}


@media only screen and (max-width: 1000px) {
    .footer-overlay {
        display: block;
        text-align: center;
    }
    .contact-us {
        margin: 3rem 1rem;
    }
}

@media only screen and (max-width: 350px) {
    .desktop-footer li {
        font-size: large;
    }
}
