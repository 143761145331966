







.about-container {
    background-image: linear-gradient(to bottom, rgba(0, 180, 126, 0.9), rgb(0, 0, 0));
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
}

.about-banner {
    width: 80%;
}

.about-heading {
    border: none;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 0 auto;
    width: 80%;
    font-size: xxx-large;
    word-break: keep-all;
    text-align: left;
    border-radius: 10px;
    padding: 1rem 0 3px 15px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.9), rgb(135, 135, 135));
    transition: all 0.2s ease-in;
}

.about-banner button:hover {
    box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.304);
    scale: 1.01;
}



.about-text-div {
    width: 80%;
    margin: 5px auto;
    padding: 3rem 7px 3rem 7px;
    transition: all 3s ease-in-out;
    animation: 3s;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
}
.about-text-div p {
    padding: 0 7px;
    font-size: large;
}

.about-close-btn {
    padding: 1rem 2rem;
    border-radius: 30px;
}


@media only screen and (max-width: 1000px) {
    .about-text-div, .about-banner {
        width: 94%;
    }
    .about-banner button {
        font-size: xx-large;
    }
    .about-heading {
        font-size: xx-large;
        width: 94%;
    }
}


@media only screen and (max-width: 700px) {
    .about-text-div {
        padding: 3rem 0px 3rem 0px;
    }
    .about-banner button {
        padding: 1rem 0 3px 8px;
    }
}

@media only screen and (max-width: 500px) {
    .about-heading {
        font-size: x-large;
    }
}

