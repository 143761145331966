

.services-container {
    background-image: linear-gradient(to bottom, rgb(246, 245, 245), rgba(0, 180, 126, 0.9));
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:  0;
    padding: 3rem 0;
}

.services-video-wrapper {
    text-align: center;
    padding: 0;
    display: block;
    justify-content: left;
    align-items: center;
    text-align: left;
    z-index: 1;
    overflow: hidden;
    margin-top: 10px;
}




 
.services-video-wrapper video {
    overflow: hidden;
    display: block;
    margin: 0;
    width: 100%;
    max-width: 800px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: left;
    z-index: 1;
}

.services-banner {
    width: 80%;
}


.services {
    width: 80%;
    margin: 0 auto;
    padding: 3rem 1rem 3rem 1rem;
}
.box {
    display: flex;
}
.services-heading {
    border: none;
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin: 0 auto;
    width: 80%;
    font-size: xxx-large;
    word-break: keep-all;
    text-align: left;
    border-radius: 10px;
    padding: 1rem 15px 3px 15px;
    background-image: linear-gradient(to top, rgba(0, 180, 126, 0.9), rgb(135, 135, 135));
    transition: all 0.2s ease-in;
}
.services-list {
    text-align: center;
    font-size: x-large;
    padding-bottom: 3rem;
    font-weight: 700;
}
.services-banner button:hover {
    box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.582);
    scale: 1.02;
}





.services-text-div {
    width: 80%;
    margin: 5px auto;
    padding: 3rem 7px 3rem 7px;
    transition: all 3s ease-in-out;
    animation: 3s;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    
}

.services-close-btn {
    padding: 1rem 2rem;
    border-radius: 30px;
}


@media only screen and (max-width: 1000px) {
    .services {
        width: 100%;
    }
    .services-banner {
        width: 100%;
    }
    .services-banner button {
        font-size: xx-large;
    }
}


@media only screen and (max-width: 1000px) {
    .services-text-div {
        width: 94%;
    }
    .services-heading {
        font-size: xx-large;
        width: 94%;
    }
   .services-list {
    font-size: large;
   }
    
}


@media only screen and (max-width: 700px) {
    .services-banner {
        font-size: xx-large;
    }

    .services-banner button {
        padding: 1rem 0 3px 8px;
    }
    
}

@media only screen and (max-width: 500px) {
    .services {
        padding: 3rem 8px;
    }

    .services-heading {
        font-size: x-large;
    }
    
}


