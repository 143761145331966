

.nav-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 100%;
    font-size: large;
    font-weight: 500;
    color: rgb(255, 255, 255);
    background-image: linear-gradient(to top, rgba(0, 180, 126, 0.9), rgb(45, 45, 45));
    background-color: white;
    margin: 0;
    position: fixed;
    z-index: 100;
    border-bottom: 2px solid rgb(178, 178, 178);
}

.nav-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}



.nav-links {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    margin: 10px 15px;
}

.nav-number {
    margin: 0 15px;
    text-align: center;
    padding-bottom: 7px;
}
a:link {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

span a:link {
    word-break: keep-all;
    color: rgb(30, 255, 0);
}


.nav-container li {
    margin: 0 15px;
}



a:visited {
    color: rgb(255, 255, 255);
}


@media only screen and (max-width: 1000px) {
    .nav-container {
        display: block;
    }
}

@media only screen and (max-width: 700px) {
    .nav-container {
        display: block;
        padding-top: 7px;
    }
    .nav-links {
        justify-content: space-evenly;
        margin: 0;
        padding: 8px 0;
        font-size: 17px;
    }
    .nav-number {
        margin: 0;
    }


}

@media only screen and (max-width: 350px) {
    
    .nav-number {
        font-size: small;
    }
    .nav-container {
        height: 11vh;
    }
    .nav-links {
        font-size: 15px;
    }

}